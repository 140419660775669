<template>
  <Sidebar
    class="audience-sidebar"
    :active="active"
    @dismiss="$emit('dismiss')"
  >
    <template v-slot:header>
      <h2>{{ title }}</h2>
    </template>

    <template v-slot:tabs>
      <div class="button-group">
        <button
          class="button-group__button"
          :class="{ active: activeTab === 'opportunities' }"
          @click="activeTab = 'opportunities'"
        >
          Case Opportunities
        </button>
        <button
          class="button-group__button"
          :class="{ active: activeTab === 'demographics' }"
          @click="activeTab = 'demographics'"
        >
          Demographics
        </button>
      </div>
    </template>
    <template v-if="activeTab === 'opportunities'">
      <div class="engagement-index" v-if="engagementIndex">
        <div class="engagement-index__header">
          <div class="engagement-index__chart">
            <CircleChart
              :metric="engagementIndex.index"
              :total="100"
              :color="getColorByEngagement(engagementIndex.index, 100)"
            />
          </div>
          <div class="engagement-index__header-content">
            <div class="engagement-index__heading">
              Healthcare Engagement Index
            </div>
            <div class="engagement-index__subheading">
              {{ persona.name }}
            </div>
          </div>
        </div>
        <ul class="engagement-index__categories">
          <li
            class="engagement-index__category"
            v-for="(score, key, index) in engagementIndex.categories"
            :key="`category-${index}`"
          >
            <div class="engagement-index__category-label">{{ key }}</div>
            <div class="engagement-index__category-metric">
              <Pill
                :count="`${score}`"
                :label="true"
                :alert="
                  getColorStatus(score, engagementIndex.weights[index] * 100) ==
                  1
                "
                :warning="
                  getColorStatus(score, engagementIndex.weights[index] * 100) ==
                  2
                "
              />
              <sub>of {{ engagementIndex.weights[index] * 100 }}</sub>
            </div>
          </li>
        </ul>
      </div>
      <div class="metrics">
        <div class="metrics__title">Case Opportunity Estimates</div>
        <SimpleMetric
          :label="'Est. New Cases Minimum'"
          :measure="getMetricLabel('incidence_min')"
          :list="true"
        />
        <SimpleMetric
          :label="'Est. New Cases Maximum'"
          :measure="getMetricLabel('incidence_max')"
          :list="true"
        />
        <SimpleMetric
          :label="'Est. Existing Cases Minimum'"
          :measure="getMetricLabel('prevalence_min')"
          :list="true"
        />
        <SimpleMetric
          :label="'Est. Existing Cases Maximum'"
          :measure="getMetricLabel('prevalence_max')"
          :list="true"
        />
      </div>
      <div class="metrics">
        <div class="metrics__title">Payer Mix</div>
        <SimpleMetric
          :label="'Total Commercial Insurance'"
          :measure="getMetricLabel('total_private_insurance')"
          :list="true"
        />
        <SimpleMetric
          :label="'Total Medicare'"
          :measure="getMetricLabel('total_medicare')"
          :list="true"
        />
        <SimpleMetric
          :label="'Total Public Insurance'"
          :measure="getMetricLabel('total_public_insurance')"
          :list="true"
        />
        <SimpleMetric
          :label="'Total Other Insurance'"
          :measure="getMetricLabel('total_other_insurance')"
          :list="true"
        />
        <SimpleMetric
          :label="'Total Uninsured'"
          :measure="getMetricLabel('total_uninsured')"
          :list="true"
        />
      </div>
    </template>
    <template v-else>
      <div class="metrics">
        <div class="metrics__title">Population</div>
        <SimpleMetric
          :label="'Total Population'"
          :measure="
            getMetricLabel(['total_population', 'total_weighted_population'])
          "
          :list="true"
        />
        <SimpleMetric
          :label="'Total Households'"
          :measure="getMetricLabel('total_households')"
          :list="true"
        />
      </div>
      <div class="metrics">
        <div class="metrics__title">Gender</div>
        <SimpleMetric
          :label="'Total Population Female'"
          :measure="getMetricLabel('total_population_female')"
          :list="true"
        />
        <SimpleMetric
          :label="'Total Population Male'"
          :measure="getMetricLabel('total_population_male')"
          :list="true"
        />
      </div>
      <div class="metrics">
        <div class="metrics__title">Age</div>
        <SimpleMetric
          :label="'Median Age'"
          :measure="getMetricLabel('median_age')"
          :list="true"
        />
        <SimpleMetric
          :label="'Total Population 0 - 17'"
          :measure="getMetricLabel('total_population_0_17')"
          :list="true"
        />
        <SimpleMetric
          :label="'Total Population 18 - 44'"
          :measure="getMetricLabel('total_population_18_44')"
          :list="true"
        />
        <SimpleMetric
          :label="'Total Population 45 - 64'"
          :measure="getMetricLabel('total_population_45_64')"
          :list="true"
        />
        <SimpleMetric
          :label="'Total Population 65 and over'"
          :measure="getMetricLabel('total_population_65_over')"
          :list="true"
        />
        <SimpleMetric
          :label="'Total Population 80 and over'"
          :measure="getMetricLabel('total_population_80_over')"
          :list="true"
        />
      </div>
      <div class="metrics">
        <div class="metrics__title">Socioeconomic</div>
        <SimpleMetric
          :label="'Per Capita Income'"
          :measure="getMetricLabel('per_capita_income', '$0,0')"
          :list="true"
        />
        <SimpleMetric
          :label="'Median Household Income'"
          :measure="getMetricLabel('median_household_income', '$0,0')"
          :list="true"
        />
      </div>
    </template>
  </Sidebar>
</template>

<script>
import Sidebar from "@/components/Sidebar";
import SimpleMetric from "@/components/SimpleMetric";
import numeral from "numeral";
import CircleChart from "@/components/charts/CircleChart";
import Pill from "@/components/Pill";
import { colors } from "@/mixins/colors";
import { audiences } from "@/mixins/audiences";

export default {
  name: "AudienceSidebar",
  mixins: [colors, audiences],
  components: {
    Sidebar,
    SimpleMetric,
    CircleChart,
    Pill,
  },
  props: {
    active: {
      type: Boolean,
      default: false,
      required: true,
    },
    audience: {
      type: Object,
      default: () => ({}),
    },
    title: {
      type: String,
      required: false,
      default: "",
    },
  },
  data() {
    return {
      activeTab: "opportunities",
    };
  },
  computed: {
    engagementIndex() {
      return this.audience.engagement_index || null;
    },
    persona() {
      return this.$_audiences_getDescription(this.audience);
    },
  },
  methods: {
    getMetricLabel(key, format) {
      format = format || "0,0";
      if (Array.isArray(key)) {
        // Check for key
        for (let i = 0; i < key.length; i++) {
          if (typeof this.audience[key[i]] != "undefined") {
            key = key[i];
            break;
          }
        }
      }
      if (
        this.audience &&
        typeof this.audience[key] != "undefined" &&
        this.audience[key]
      ) {
        return numeral(this.audience[key]).format(format);
      }

      return "-";
    },
    getColorByEngagement(score, total) {
      const comp = this.getColorStatus(score, total);
      if (comp == 1) {
        return this.colors.red;
      } else if (comp == 2) {
        return this.colors.yellow;
      } else if (comp == 3) {
        return this.colors.green;
      }

      return "blue";
    },
    getColorStatus(score, total) {
      const comp = score / total;
      if (comp <= 0.3333) {
        return 1;
      } else if (comp > 0.3333 && comp <= 0.6666) {
        return 2;
      } else if (comp > 0.6666) {
        return 3;
      }

      return null;
    },
  },
};
</script>

<style lang="scss">
@import "@/scss/colors";
@import "@/scss/button";

.audience-sidebar {
  h2 {
    font-weight: 600;
    button {
      cursor: pointer;
      background: transparent;
      border: none;
      padding: 0;
    }
    svg {
      width: 16px;
      fill: $medium-gray;
    }
  }

  .engagement-index {
    margin-bottom: 24px;
    padding-bottom: 24px;
    border-bottom: solid 2px $fade-gray;

    &__header {
      display: flex;
      align-items: center;
      margin-bottom: 1rem;

      &-content {
        padding-left: 1rem;
      }
    }

    &__heading {
      font-weight: 600;
      margin-bottom: 8px;
    }

    &__subheading {
      font-size: 13px;
      color: $medium-gray;
    }

    &__chart {
      width: 72px;
    }

    &__categories {
      display: block;
    }

    &__category {
      display: flex;
      align-items: center;
      padding: 0.25rem 0;

      &-label {
        font-size: 13px;
      }
      &-metric {
        margin-left: auto;

        .pill {
          min-width: 2.5rem;
        }

        sub {
          display: inline-block;
          text-align: right;
          font-size: 10px;
          color: $medium-gray;
          margin-left: 4px;
          min-width: 1.75rem;
        }
      }
    }
  }

  .metrics {
    &:not(:first-of-type) {
      margin-top: 1rem;
    }

    &__title {
      font-weight: 600;
      margin-bottom: 1rem;
    }
  }

  .audience__heading {
    width: 100%;
    padding-right: 0;

    &-label {
      font-weight: 600;
    }
  }

  .button-group {
    display: flex;
    height: 40px;

    &__button {
      height: 40px;
      flex: 1 1 0px;
      justify-content: center;
      border: none;
      border-bottom: solid 1px $disabled;
      background: transparent;
      cursor: pointer;
      font-family: inherit;
      padding: 0.75em 0;
      color: $medium-gray;
      position: relative;
      font-weight: 600;

      &.active {
        border-bottom-color: $light-blue;
        color: $gray;

        &:after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 2px;
          background: $light-blue;
        }
      }

      .pill {
        margin-left: 0;
        margin-right: 0.5rem;
      }
    }

    & + .metrics {
      margin-top: 2rem;
    }
  }
}
</style>
