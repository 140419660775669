<template>
  <div class="audience">
    <Loading :active="isLoading" :fullscreen="true" />
    <AudienceSidebar
      :active="audienceSidebarActive"
      :audience="selectedAudience"
      :title="selectedAudienceTitle"
      @dismiss="audienceSidebarActive = false"
    />
    <div class="hero hero--identify">
      <div class="container">
        <h2>{{ $_audiences_audience.name }}</h2>
        <div class="controls-wrapper form-control">
          <div class="select">
            <select v-model="$data.$_audiences_caseOpportunityFilter">
              <option value="min">Minimum Estimate</option>
              <option value="max">Maximum Estimate</option>
            </select>
          </div>
          <button class="button" @click="onDownload">Download</button>
        </div>
      </div>
    </div>
    <div class="container audience__overview">
      <div class="audience__overview-charts">
        <div class="card">
          <h2>Opportunities By Payer Mix</h2>
          <div class="audience__doughnut-chart">
            <div class="chart-metric-wrapper">
              <span class="metric">{{ caseOpportunitiesLabel }}</span>
              <span class="label">Case Opportunities</span>
            </div>
            <DoughnutChart
              :height="240"
              :chart-data="doughnutChartData"
              :tooltips="doughnutChartTooltips"
            />
          </div>
          <ListLegend :list="legend" />
        </div>
      </div>
      <div class="audience__overview-map">
        <div class="card">
          <div id="map-canvas">
            <Loading :active="geometryLoading" :fullscreen="false" />
            <transition name="fade-up">
              <div class="audience__map-overlay" v-if="filterOverlayActive">
                <h2>
                  <strong>{{ filteredCaseOpportunities | number }}</strong> of
                  <strong>{{ caseOpportunities | number }}</strong> Case
                  Opportunities
                  <div class="audience__map-overlay-button-group">
                    <button
                      class="button outline button--icon-only"
                      @click="onReset"
                    >
                      <BaseSVG :src="require('@/assets/reload-icon.svg')" />
                    </button>
                    <button
                      class="button outline button--icon-only"
                      @click="onDownloadFiltered"
                    >
                      <BaseSVG :src="require('@/assets/download-icon.svg')" />
                    </button>
                    <button class="button" @click="onFilter">Apply</button>
                  </div>
                </h2>
                <div class="audience__filter-item">
                  <div class="audience__filter-item-label">
                    Min. Healthcare Engagement Index
                  </div>
                  <div class="audience__filter-item-range">
                    <RangeSlider
                      :maxValue="healthcareIndexFilterMax"
                      :minValue="healthcareIndexFilterMin"
                      :step="1"
                      :inverse="true"
                      v-model="healthcareIndexFilter"
                    />
                  </div>
                  <div class="audience__filter-item-output">
                    {{ healthcareIndexFilter }}
                  </div>
                </div>
                <div class="audience__filter-item">
                  <div class="audience__filter-item-label">
                    Min. Case Opportunities
                  </div>
                  <div class="audience__filter-item-range">
                    <RangeSlider
                      :maxValue="caseOpportunitiesFilterMax"
                      :minValue="caseOpportunitiesFilterMin"
                      :step="1"
                      :inverse="true"
                      v-model="caseOpportunitiesFilter"
                    />
                  </div>
                  <div class="audience__filter-item-output">
                    {{ caseOpportunitiesFilter }}
                  </div>
                </div>
                <div
                  class="audience__filter-close"
                  @click="filterOverlayActive = false"
                >
                  <BaseSVG :src="require('@/assets/angle-down-icon.svg')" />
                  <span>Hide Filters</span>
                </div>
              </div>
            </transition>
            <transition name="fade-up">
              <div class="audience__map-toolbar" v-if="!filterOverlayActive">
                <button class="button" @click="filterOverlayActive = true">
                  <BaseSVG :src="require('@/assets/control-panel-icon.svg')" />
                  Show Filters
                </button>
              </div>
            </transition>
            <gmap-map
              ref="mapRef"
              :center="{ lat: 37.0902, lng: -95.7129 }"
              :zoom="5"
              :map-type-id="'roadmap'"
              :options="{
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: false,
                zoomControl: false,
                styles: mapStyle,
              }"
            >
            </gmap-map>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <h2>Geo-Demographic Niches</h2>
      <template v-if="$_audiences_audience.demographics">
        <div
          class="card audience__persona"
          v-for="(persona, index) in $_audiences_personas"
          :key="`persona-${index}`"
          @click="onSelectPersona(persona)"
        >
          <BaseSVG
            class="audience__persona-arrow-icon"
            :src="require('@/assets/arrow-45-icon.svg')"
          />
          <PersonaListItem :persona="persona" :detailed="true" />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Loading from "@/components/Loading";
import DoughnutChart from "@/components/charts/DoughnutChart";
import { colors } from "@/mixins/colors";
import ListLegend from "@/components/ListLegend";
import { maps } from "@/mixins/maps";
import { gmapApi } from "vue2-google-maps";
import styles from "@/mixins/mapStyles";
import { audiences } from "@/mixins/audiences";
import PersonaListItem from "@/components/PersonaListItem";
import numeral from "numeral";
import AudienceSidebar from "@/components/AudienceSidebar";
import Papa from "papaparse";
import FileDownload from "js-file-download";
import BaseSVG from "@/components/base/BaseSVG";
import RangeSlider from "@/components/RangeSlider";

export default {
  name: "Audience",
  mixins: [colors, maps, audiences],
  components: {
    Loading,
    DoughnutChart,
    ListLegend,
    PersonaListItem,
    AudienceSidebar,
    BaseSVG,
    RangeSlider,
  },
  data() {
    return {
      mapStyle: styles,
      geometry: null,
      geometryLoading: false,
      audienceSidebarActive: false,
      selectedAudience: null,
      selectedAudienceTitle: null,
      filterOverlayActive: false,
      filteredZipCodes: () => [],
      healthcareIndexFilter: 0,
      healthcareIndexFilterMin: 0,
      healthcareIndexFilterMax: 0,
      caseOpportunitiesFilter: 0,
      caseOpportunitiesFilterMin: 0,
      caseOpportunitiesFilterMax: 0,
    };
  },
  computed: {
    isLoading() {
      return this.$store.getters["audiences/loading"];
    },
    doughnutChartData() {
      let data = {
        labels: ["Private", "Public", "Medicare", "Other", "Uninsured"],
        datasets: [
          {
            data: [0, 0, 0, 0, 0],
            backgroundColor: [
              this.colors.lightBlueTint0,
              this.colors.lightBlueTint1,
              this.colors.lightBlueTint2,
              this.colors.lightBlueTint3,
              this.colors.lightBlueTint4,
            ],
            borderColor: [
              "transparent",
              "transparent",
              "transparent",
              "transparent",
              "transparent",
            ],
          },
        ],
      };

      if (this.payerMix) {
        data.datasets[0].data[0] = this.payerMix[0].total; // Commercial
        data.datasets[0].data[1] = this.payerMix[2].total; // Public
        data.datasets[0].data[2] = this.payerMix[1].total; // Medicare
        data.datasets[0].data[3] = this.payerMix[3].total; // Other
        data.datasets[0].data[4] = this.payerMix[4].total; // Uninsured
      }

      return data;
    },
    doughnutChartTooltips() {
      return {
        callbacks: {
          label: function (tooltipItem, val) {
            return numeral(
              val.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
            ).format("0,0");
          },
        },
      };
    },
    legend() {
      return [
        {
          label: "Commercial Insurance",
          total: this.payerMix ? this.payerMix[0].total : 0,
          color: this.colors.lightBlueTint0,
        },
        {
          label: "Public Insurance",
          total: this.payerMix ? this.payerMix[2].total : 0,
          color: this.colors.lightBlueTint1,
        },
        {
          label: "Medicare",
          total: this.payerMix ? this.payerMix[1].total : 0,
          color: this.colors.lightBlueTint2,
        },
        {
          label: "Other",
          total: this.payerMix ? this.payerMix[3].total : 0,
          color: this.colors.lightBlueTint3,
        },
        {
          label: "Uninsured",
          total: this.payerMix ? this.payerMix[4].total : 0,
          color: this.colors.lightBlueTint4,
        },
      ];
    },
    google() {
      return gmapApi();
    },
    zipCodes() {
      let list = [];
      if (this?.$_audiences_audience?.demographics) {
        this.$_audiences_audience.demographics.map((county) => {
          county.zip_codes.map((zip_code) => {
            list.push(zip_code);
          });
        });

        // Sort list by prevalence max
        list.sort((a, b) => (a.prevalence_max > b.prevalence_max ? 1 : -1));
      }

      return list;
    },
    payerMix() {
      if (this.$_audiences_audience) {
        return this.$_audiences_payerMix(this.$_audiences_audience, true);
      }

      return null;
    },
    caseOpportunities() {
      if (this.$_audiences_audience) {
        return this.$_audiences_getCaseOpportunities(this.$_audiences_audience);
      }

      return null;
    },
    caseOpportunitiesLabel() {
      return numeral(this.caseOpportunities).format("0a");
    },
    filteredCaseOpportunities() {
      let sum = 0;
      try {
        if (this.filteredZipCodes.length) {
          return this.filteredZipCodes.reduce((total, item) => {
            return (total +=
              item[
                `incidence_${this.$data.$_audiences_caseOpportunityFilter}`
              ] +
              item[
                `prevalence_${this.$data.$_audiences_caseOpportunityFilter}`
              ]);
          }, 0);
        }
      } catch (e) {
        console.log(e);
      }

      return sum;
    },
  },
  methods: {
    onSelectZipCode(feature) {
      const zipCode = feature.getProperty("zip_code");
      if (zipCode && this.zipCodes) {
        let match = this.zipCodes.find((item) => {
          return item.zip_code == zipCode;
        });

        if (typeof match != "undefined") {
          this.selectedAudience = match;
          this.selectedAudienceTitle = `Zip Code ${zipCode}`;
          this.audienceSidebarActive = true;
        }
      }
    },
    onSelectPersona(persona) {
      this.selectedAudience = persona;
      const description = this.$_audiences_getDescription(persona);
      this.selectedAudienceTitle = description?.name;
      this.audienceSidebarActive = true;
    },
    drawGeometry(list) {
      list = list || this.geometry;
      this.$_maps_drawGeometry(
        list,
        this.$refs.mapRef,
        this.google,
        this.getStyle,
        this.onSelectZipCode
      );
    },
    getStyle(feature) {
      let style = {
        fillColor: this.colors.lightBlueTint2,
        fillOpacity: 0.5,
        strokeColor: this.colors.lightBlueTint1,
        strokeWeight: 1,
        strokeOpacity: 0.75,
      };

      const cluster_id = feature.getProperty("cluster_id");

      if (cluster_id == 1) {
        style.fillColor = this.colors.lightBlueTint2;
        style.strokeColor = this.colors.lightBlueTint1;
      } else if (cluster_id == 2) {
        style.fillColor = this.colors.lightBlueTint1;
        style.strokeColor = this.colors.lightBlueTint0;
      } else if (cluster_id == 3) {
        style.fillColor = this.colors.lightBlueTint3;
        style.fillColor = this.colors.lightBlueTint2;
      } else if (cluster_id == 4) {
        style.fillColor = this.colors.lightBlueTint0;
        style.strokeColor = this.colors.blue;
      }

      const quartile = this.getQuantile(feature.getProperty("zip_code"), 4);
      if (quartile <= 1) {
        style.fillOpacity = 0.25;
        style.strokeOpacity = 0.375;
      } else if (quartile == 2) {
        style.fillOpacity = 0.45;
        style.strokeOpacity = 0.575;
      } else if (quartile == 3) {
        style.fillOpacity = 0.65;
        style.strokeOpacity = 0.85;
      } else if (quartile == 4) {
        style.fillOpacity = 0.85;
        style.strokeOpacity = 1;
      }

      return style;
    },
    getQuantile(zipCode, quantile) {
      quantile = quantile || 4;
      const marker = this.zipCodes.length / quantile;

      // Find the index of the zip code
      const index = this.zipCodes.findIndex((item) => {
        return item.zip_code == zipCode;
      });

      return Math.round(index / marker);
    },
    onDownload() {
      const data = this.$_audiences_getConditionInfoByZipCode(this.zipCodes);
      const csv = Papa.unparse(data);
      FileDownload(csv, `${this.$_audiences_audience.name}.csv`);
    },
    onFilter() {
      this.filteredZipCodes = this.zipCodes.filter((item) => {
        return (
          item.engagement_index.index >= this.healthcareIndexFilter &&
          item[`incidence_${this.$data.$_audiences_caseOpportunityFilter}`] +
            item[
              `prevalence_${this.$data.$_audiences_caseOpportunityFilter}`
            ] >=
            this.caseOpportunitiesFilter
        );
      });

      let list = this.filteredZipCodes.map((item) => {
        return this.getZipCodeGeom(item.zip_code);
      });

      this.drawGeometry(list);
      //this.filterOverlayActive = false;
    },
    onReset() {
      this.drawGeometry();
      this.filterOverlayActive = false;
      this.healthcareIndexFilter = this.healthcareIndexFilterMin;
      this.caseOpportunitiesFilter = this.caseOpportunitiesFilterMin;
    },
    onDownloadFiltered() {
      const data = this.$_audiences_getConditionInfoByZipCode(
        this.filteredZipCodes
      );
      const csv = Papa.unparse(data);
      FileDownload(csv, `${this.$_audiences_audience.name}-filtered.csv`);
    },
    getZipCodeGeom(zip_code) {
      return this.geometry.find((item) => {
        return item.properties.zip_code == zip_code;
      });
    },
  },
  mounted() {
    // Get the audience
    this.$store
      .dispatch("audiences/getAudience", {
        id: this.$route.params.id,
      })
      .then((res) => {
        if (res.status < 300) {
          // Set the filtered zip codes to demographics
          let zips = [];
          res.data.demographics.map((county) => {
            zips = [...zips, ...county.zip_codes];
          });
          this.filteredZipCodes = zips;
          // Set the healthcare index filter
          let healthcareIndexMin = null;
          let healthcareIndexMax = null;
          // Set the case opportunity filter
          let caseOpportunityMin = null;
          let caseOpportunityMax = null;

          this.geometryLoading = true;
          // Get zip code list for geometry
          // res.data.demographics -> each -> zip_codes -> each
          let list = [];
          res.data.demographics.map((county) => {
            county.zip_codes.map((zip) => {
              if (list.indexOf(zip.zip_code) === -1) {
                list.push(zip.zip_code);

                // healthcare index filter
                if (zip?.engagement_index) {
                  if (
                    !healthcareIndexMin ||
                    zip.engagement_index.index < healthcareIndexMin
                  ) {
                    healthcareIndexMin = zip.engagement_index.index;
                  }

                  if (
                    !healthcareIndexMax ||
                    zip.engagement_index.index > healthcareIndexMax
                  ) {
                    healthcareIndexMax = zip.engagement_index.index;
                  }
                }

                // case opportunity filter
                if (zip?.incidence_min && zip?.prevalence_min) {
                  if (
                    !caseOpportunityMin ||
                    zip.incidence_min + zip.prevalence_min < caseOpportunityMin
                  ) {
                    caseOpportunityMin = zip.incidence_min + zip.prevalence_min;
                  }

                  if (
                    !caseOpportunityMax ||
                    zip.incidence_max + zip.prevalence_max > caseOpportunityMax
                  ) {
                    caseOpportunityMax = zip.incidence_max + zip.prevalence_max;
                  }
                }
              }
            });
          });

          this.healthcareIndexFilter = this.healthcareIndexFilterMin =
            healthcareIndexMin;
          this.healthcareIndexFilterMax = healthcareIndexMax;
          this.caseOpportunitiesFilter = this.caseOpportunitiesFilterMin =
            caseOpportunityMin;
          this.caseOpportunitiesFilterMax = caseOpportunityMax;

          const zipCodes = list.join(",");
          // Get geometry of zip codes
          axios
            .get(
              `${process.env.VUE_APP_REMOTE_BASE}${process.env.VUE_APP_API_PATH}/service/audiences/get-geometry?zip_codes=${zipCodes}`
            )
            .then((res) => {
              this.geometry = res.data.map((item) => {
                return {
                  geom: item.zip_code_geom,
                  properties: {
                    zip_code: item.zip_code,
                    cluster_id: item.cluster_id,
                    prevalence_max: item.prevalence_max,
                  },
                };
              });

              this.drawGeometry();
            })
            .catch((err) => {
              console.log(err);
            })
            .then(() => {
              this.geometryLoading = false;
            });
        }
      });
  },
  watch: {
    google() {
      this.drawGeometry();
    },
  },
};
</script>

<style lang="scss">
@import "@/scss/colors";
@import "@/scss/hero";
@import "@/scss/button";
@import "@/scss/forms";

.audience {
  &__overview {
    display: flex;
    margin-bottom: 2rem;

    &-charts {
      width: 33.3333%;
    }

    &-map {
      position: relative;
      padding-left: 2rem;
      width: 66.6666%;
      min-height: 100%;

      #map-canvas {
        position: relative;
        width: calc(100% + 3rem);
        height: calc(100% + 3rem);
        margin: -1.5rem;
        border-radius: 6px;
        overflow: hidden;
      }

      .card {
        height: 100%;
      }
    }
  }

  &__map-toolbar {
    display: flex;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding-bottom: 24px;
    z-index: 1;
    align-items: center;

    .button {
      height: 32px;
      border-radius: 16px;
      font-size: 14px;
      background: #fff;
      color: $dark-blue;
      font-weight: 600;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
      margin-right: 24px;
      margin-left: auto;

      svg {
        fill: $dark-blue;
        margin-left: 0;
        margin-right: 0.5rem;
      }

      &:hover {
        background: #fff;
        color: $dark-blue;
      }
    }
  }

  &__map-overlay {
    position: absolute;
    border-radius: 8px 8px 0 0;
    bottom: 0;
    left: 0;
    width: 100%;
    background: rgba(255, 255, 255, 0.95);
    z-index: 2;
    padding: 1.5rem 1.5rem 2.5rem 1.5rem;
    box-shadow: 0 -8px 16px rgba(35, 35, 103, 0.075);

    h2 {
      margin-bottom: 1.25rem !important;
      font-weight: 400 !important;

      strong {
        font-weight: 700 !important;
        margin: 0 4px;

        &:first-of-type {
          margin-left: 0;
        }
      }
    }

    &-button-group {
      margin-left: auto;
      display: flex;
      justify-content: flex-end;

      .button {
        font-size: 12px;
        height: 28px;

        & + .button {
          margin-left: 0.25rem !important;
        }

        svg {
          fill: $medium-gray;
          height: 14px;
        }

        &:hover {
          svg {
            fill: #fff;
          }
        }
      }
    }
  }

  &__filter-close {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 600;
    color: $medium-gray;
    cursor: pointer;

    svg {
      width: 9px;
      margin-right: 9px;
      fill: $medium-gray;
    }
  }

  &__filter-item {
    display: flex;
    align-items: center;
    width: 100%;

    &-label {
      font-size: 14px;
      width: 40%;
      color: $medium-gray;
    }

    &-range {
      width: calc(60% - 48px);
    }

    &-output {
      width: 48px;
      font-weight: 600;
      text-align: right;
    }
  }

  &__filter-item + &__filter-item {
    margin-top: 1.5rem;
  }

  &__doughnut-chart {
    position: relative;

    & + .list-legend {
      margin-top: 2rem;
    }

    .chart-metric-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      pointer-events: none;
      z-index: 0;

      & + div {
        position: relative;
        z-index: 1;
      }

      .metric {
        font-size: 2.25rem;
        font-weight: 600;
      }

      .label {
        font-size: 0.75rem;
        color: $medium-gray;
      }
    }
  }

  &__persona {
    margin-bottom: 1rem;
    position: relative;
    cursor: pointer;
    transition: transform 0.425s, box-shadow 0.425s;

    &:hover {
      transform: scale(1.0175);
      box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1) !important;
    }

    &-arrow-icon {
      position: absolute;
      top: 1rem;
      right: 1rem;
      width: 0.75rem;
      path {
        stroke: $medium-gray;
      }
    }
  }
}
</style>