<template>
  <div class="range-slider" :class="{ 'range-slider--inverse': inverse }">
    <div class="range-slider__tracker" :style="getTrackerStyle()"></div>
    <input
      type="range"
      :min="minValue"
      :max="maxValue"
      :step="step"
      class="slider"
      :class="{ highlight: highlight }"
      :value="modelValue"
      @input="onInput"
    />
  </div>
</template>

<script>
export default {
  name: "RangeSlider",
  props: {
    value: {},
    maxValue: {
      default: 100,
    },
    minValue: {
      type: Number,
      required: false,
      default: 0,
    },
    highlight: {
      default: false,
    },
    inverse: {
      type: Boolean,
      required: false,
      default: false,
    },
    step: {
      type: Number,
      required: false,
      default: 0.5,
    },
  },
  data: function () {
    return {
      modelValue: null,
    };
  },
  methods: {
    onInput: function (e) {
      this.modelValue = e.target.value;
      this.$emit("input", this.modelValue);
    },
    getTrackerStyle() {
      const width = `${
        100 -
        Math.round(
          ((this.modelValue - this.minValue) /
            (this.maxValue - this.minValue)) *
            100
        )
      }%`;
      return {
        width,
      };
    },
  },
  mounted() {
    this.modelValue = this.value;
  },
};
</script>

<style lang="scss">
@import "@/scss/colors";

.range-slider {
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;

  &:before,
  &__tracker {
    position: absolute;
    height: 2px;
    top: 50%;
    margin-top: -1px;
    pointer-events: none;
  }

  &:before {
    content: "";
    width: 100%;
    background: $disabled;
  }

  &__tracker {
    left: 0;
    background: linear-gradient(90deg, $green 0%, $light-blue 100%);
  }

  .slider {
    position: relative;
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 2px;
    background: transparent;
    outline: none;
    -webkit-transition: 0.2s;
    transition: opacity 0.2s;
    margin: 0;
    z-index: 1;

    &:hover {
      opacity: 1;
    }

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 0.75rem;
      height: 0.75rem;
      background: $light-blue;
      background: linear-gradient(315deg, $green 0%, $light-blue 100%);
      cursor: pointer;
      border-radius: 50%;
    }

    &::-moz-range-thumb {
      width: 0.75rem;
      height: 0.75rem;
      background: $light-blue;
      background: linear-gradient(315deg, $green 0%, $light-blue 100%);
      cursor: pointer;
      border-radius: 50%;
    }

    &.highlight {
      &::-webkit-slider-thumb {
        background: $yellow;
        background: linear-gradient(315deg, $orange 0%, $yellow 100%);
      }

      &::-moz-range-thumb {
        background: $yellow;
        background: linear-gradient(315deg, $orange 0%, $yellow 100%);
      }
    }
  }

  &--inverse {
    .range-slider__tracker {
      left: auto;
      right: 0;
    }
  }
}
</style>